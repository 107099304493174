import React from "react"
import { Order } from "../../models/order"
import OrderDetails from "../../page-components/account-pages/my-account-index/your-orders/order-details"

const Thanks = ({location}) => {

    let order: Order = new Order(location?.state?.order?.order ?? null)

    return (
        <div>
            <OrderDetails ord={order}/>
        </div>
    )
}


export default Thanks